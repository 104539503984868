export default [
  {
    title: 'Eu tenho o carro estacionado __________ garagem.',
    choices: [
      {
        value: 'em o',
        score: 0,
      },
      {
        value: 'na',
        score: 1,
      },
      {
        value: 'no',
        score: 0,
      },
    ],
  },
  {
    title: 'Ele __________ uma casa nova.',
    choices: [
      {
        value: 'há',
        score: 0,
      },
      {
        value: 'tem',
        score: 1,
      },
      {
        value: 'a',
        score: 0,
      },
    ],
  },
  {
    title: 'Vamos __________ praia.',
    choices: [
      {
        value: 'a',
        score: 0,
      },
      {
        value: 'em',
        score: 0,
      },
      {
        value: 'à',
        score: 1,
      },
    ],
  },
  {
    title: 'Quero um gelado __________ morango.',
    choices: [
      {
        value: 'De',
        score: 1,
      },
      {
        value: 'do',
        score: 0,
      },
      {
        value: 'ao',
        score: 0,
      },
    ],
  },
  {
    title: 'Até que horas __________ aberta a biblioteca?',
    choices: [
      {
        value: 'Está',
        score: 1,
      },
      {
        value: 'é',
        score: 0,
      },
      {
        value: 'são',
        score: 0,
      },
    ],
  },
  {
    title: 'A caneta é __________.',
    choices: [
      {
        value: 'meu',
        score: 0,
      },
      {
        value: 'minha',
        score: 1,
      },
      {
        value: 'meus',
        score: 0,
      },
    ],
  },
  {
    title: 'Nós __________ portugueses.',
    choices: [
      {
        value: 'somos',
        score: 1,
      },
      {
        value: 'estamos',
        score: 0,
      },
      {
        value: 'são',
        score: 0,
      },
    ],
  },
  {
    title: 'Compras __________ sapatos que estão aqui.',
    choices: [
      {
        value: 'esses',
        score: 0,
      },
      {
        value: 'aqueles',
        score: 0,
      },
      {
        value: 'estes',
        score: 1,
      },
    ],
  },
  {
    title: 'A casa tem uma área de __________ metros quadrados.',
    choices: [
      {
        value: 'cento vinte',
        score: 0,
      },
      {
        value: 'cento e vinte',
        score: 1,
      },
      {
        value: 'cem e vinte',
        score: 0,
      },
    ],
  },
  {
    title: 'Os amigos do Ricardo leram __________ o que ele publicou.',
    choices: [
      {
        value: 'tudo',
        score: 1,
      },
      {
        value: 'todo',
        score: 0,
      },
      {
        value: 'todos',
        score: 0,
      },
    ],
  },
  {
    title: 'Quando vou à praia levo sempre __________.',
    choices: [
      {
        value: 'guarda-chuva',
        score: 0,
      },
      {
        value: 'luvas',
        score: 0,
      },
      {
        value: 'uma toalha',
        score: 1,
      },
    ],
  },
  {
    title: 'Eles têm muita admiração __________ Joana.',
    choices: [
      {
        value: 'por',
        score: 0,
      },
      {
        value: 'para',
        score: 0,
      },
      {
        value: 'pela',
        score: 1,
      },
    ],
  },
  {
    title: 'Viste o João ? Ontem pensei __________ muitas vezes.',
    choices: [
      {
        value: 'dele',
        score: 0,
      },
      {
        value: 'nele',
        score: 1,
      },
      {
        value: 'a ele',
        score: 0,
      },
    ],
  },
  {
    title: 'Agora __________ fazer um bolo.',
    choices: [
      {
        value: 'estou',
        score: 0,
      },
      {
        value: 'estou a',
        score: 1,
      },
      {
        value: 'sou a',
        score: 0,
      },
    ],
  },
  {
    title: 'Eu trato de __________.',
    choices: [
      {
        value: 'eu',
        score: 0,
      },
      {
        value: 'tu',
        score: 0,
      },
      {
        value: 'mim',
        score: 1,
      },
    ],
  },
  {
    title: 'Ontem ele não __________ os amigos',
    choices: [
      {
        value: 'vê',
        score: 0,
      },
      {
        value: 'viu',
        score: 1,
      },
      {
        value: 'veio',
        score: 0,
      },
    ],
  },
  {
    title: 'Se estás doente, vai ao __________ .',
    choices: [
      {
        value: 'Dentista',
        score: 0,
      },
      {
        value: 'Enfermeiro',
        score: 0,
      },
      {
        value: 'Médico',
        score: 1,
      },
    ],
  },
  {
    title: 'Na nossa infância __________ muito de férias',
    choices: [
      {
        value: 'íamos',
        score: 1,
      },
      {
        value: 'vamos',
        score: 0,
      },
      {
        value: 'ido',
        score: 0,
      },
    ],
  },
  {
    title: 'O João faz anos hoje, temos de lhe dar os __________.',
    choices: [
      {
        value: 'cumprimentos',
        score: 0,
      },
      {
        value: 'felicidades',
        score: 0,
      },
      {
        value: 'parabéns',
        score: 1,
      },
    ],
  },
  {
    title: 'É necessário que tu __________ o jornal',
    choices: [
      {
        value: 'lês',
        score: 0,
      },
      {
        value: 'leias',
        score: 1,
      },
      {
        value: 'lia',
        score: 0,
      },
    ],
  },
  {
    title: 'Elas acabaram de __________.',
    choices: [
      {
        value: 'se deitar',
        score: 1,
      },
      {
        value: 'se deitarem',
        score: 0,
      },
      {
        value: 'Deitarem-se',
        score: 0,
      },
    ],
  },
  {
    title: 'Disse-nos que __________ devagar.',
    choices: [
      {
        value: 'Fôssemos',
        score: 1,
      },
      {
        value: 'vamos',
        score: 0,
      },
      {
        value: 'temos',
        score: 0,
      },
    ],
  },
  {
    title: 'Apesar de eles __________ a pé, não estão cansados.',
    choices: [
      {
        value: 'virem',
        score: 1,
      },
      {
        value: 'vieram',
        score: 0,
      },
      {
        value: 'verem',
        score: 0,
      },
    ],
  },
  {
    title: 'O avião __________ levantou voo vai para Paris',
    choices: [
      {
        value: 'a qual',
        score: 0,
      },
      {
        value: 'quem',
        score: 0,
      },
      {
        value: 'que',
        score: 1,
      },
    ],
  },
  {
    title: 'Faz lá como tu __________.',
    choices: [
      {
        value: 'queres',
        score: 0,
      },
      {
        value: 'querias',
        score: 0,
      },
      {
        value: 'quiseres',
        score: 1,
      },
    ],
  },
];
