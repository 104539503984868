import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const Bag = styled.div`
    color: #E93A3A;
    font-weight: 900;
    font-size: 1.2em;
`;

function Error({ error }) {
  return <Bag>{error}</Bag>;
}

Error.propTypes = {
  error: PropTypes.string.isRequired,
};

export default Error;
