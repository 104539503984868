export default [
  {
    title: 'Yo tengo __________ coche estacionado en el garaje.',
    choices: [
      {
        value: 'Una',
        score: 0,
      },
      {
        value: 'Un',
        score: 1,
      },
      {
        value: 'Uno',
        score: 0,
      },
    ],
  },
  {
    title: '¿__________ nombres, por favor?',
    choices: [
      {
        value: 'Tu',
        score: 0,
      },
      {
        value: 'Sus',
        score: 1,
      },
      {
        value: 'Mi',
        score: 0,
      },
    ],
  },
  {
    title: 'Vamos __________ Madrid, ¿no?',
    choices: [
      {
        value: 'En',
        score: 0,
      },
      {
        value: 'Ante',
        score: 0,
      },
      {
        value: 'A',
        score: 1,
      },
    ],
  },
  {
    title: 'De postre quiero helado __________ vainilla.',
    choices: [
      {
        value: 'De',
        score: 1,
      },
      {
        value: 'A',
        score: 0,
      },
      {
        value: 'Hasta',
        score: 0,
      },
    ],
  },
  {
    title: '¿Hasta qué hora __________ abierta la biblioteca?',
    choices: [
      {
        value: 'Está',
        score: 1,
      },
      {
        value: 'Es',
        score: 0,
      },
      {
        value: 'Lleva',
        score: 0,
      },
    ],
  },
  {
    title: 'París es una ciudad romántica, cultural y __________',
    choices: [
      {
        value: 'Costera',
        score: 0,
      },
      {
        value: 'Antigua',
        score: 1,
      },
      {
        value: 'Hispánica',
        score: 0,
      },
    ],
  },
  {
    title: 'Perdona, ¿sabes si hay un museo  por __________?',
    choices: [
      {
        value: 'Aquí',
        score: 1,
      },
      {
        value: 'Cerca',
        score: 0,
      },
      {
        value: 'Lejos',
        score: 0,
      },
    ],
  },
  {
    title: 'Doctor, me duele __________',
    choices: [
      {
        value: 'Los ojos',
        score: 0,
      },
      {
        value: 'Las manos',
        score: 0,
      },
      {
        value: 'La cabeza',
        score: 1,
      },
    ],
  },
  {
    title: 'La crema catalana, es un postre típico de Cataluña. __________ huevos, leche y azúcar.',
    choices: [
      {
        value: 'Está',
        score: 0,
      },
      {
        value: 'Lleva',
        score: 1,
      },
      {
        value: 'Es',
        score: 0,
      },
    ],
  },
  {
    title: '__________ coger el metro y bajar en la estación Callao.',
    choices: [
      {
        value: 'Tengo que',
        score: 1,
      },
      {
        value: 'Opino que',
        score: 0,
      },
      {
        value: 'Pienso que',
        score: 0,
      },
    ],
  },
  {
    title: 'Para viajar, siempre llevo __________',
    choices: [
      {
        value: 'Pasaporte, móvil y baño.',
        score: 0,
      },
      {
        value: 'Pasaporte, equipaje y móvil.',
        score: 1,
      },
      {
        value: 'Pasaporte, mesa y móvil.',
        score: 0,
      },
    ],
  },
  {
    title: 'Estudia inglés por su trabajo; necesita un nivel muy bueno __________ comunicarse con los clientes.',
    choices: [
      {
        value: 'Por',
        score: 0,
      },
      {
        value: 'Porque',
        score: 0,
      },
      {
        value: 'Para',
        score: 1,
      },
    ],
  },
  {
    title: '¿Te importa si abro la ventana? ¡__________!',
    choices: [
      {
        value: '¡Hace un frío!',
        score: 0,
      },
      {
        value: '¡Hace un calor!',
        score: 1,
      },
      {
        value: '¡Está lloviendo!',
        score: 0,
      },
    ],
  },
  {
    title: 'Llámame más tarde. ¡Estoy __________!',
    choices: [
      {
        value: 'Bailo',
        score: 0,
      },
      {
        value: 'Comiendo',
        score: 1,
      },
      {
        value: 'Desde',
        score: 0,
      },
    ],
  },
  {
    title: 'En otoño, __________ llover muchísimo.',
    choices: [
      {
        value: 'Suelen',
        score: 0,
      },
      {
        value: 'Soléis',
        score: 0,
      },
      {
        value: 'Suele',
        score: 1,
      },
    ],
  },
  {
    title: 'Nunca __________ en Sevilla, pero nos encantaría ir.',
    choices: [
      {
        value: 'Hemos ido',
        score: 0,
      },
      {
        value: 'Hemos estado',
        score: 1,
      },
      {
        value: 'Hemos visitado',
        score: 0,
      },
    ],
  },
  {
    title: 'Te ves muy cansada. ¿Por qué no vas al __________?',
    choices: [
      {
        value: 'Dentista',
        score: 0,
      },
      {
        value: 'Enfermero',
        score: 0,
      },
      {
        value: 'Médico',
        score: 1,
      },
    ],
  },
  {
    title: '¿Qué os pareció la cena de ayer? ¿Os __________?',
    choices: [
      {
        value: 'Gustó',
        score: 1,
      },
      {
        value: 'Gusta',
        score: 0,
      },
      {
        value: 'Ha gustado',
        score: 0,
      },
    ],
  },
  {
    title: 'He aprobado el examen de francés. ¡__________!',
    choices: [
      {
        value: '¡Ya!',
        score: 0,
      },
      {
        value: '¡Ánimo!',
        score: 0,
      },
      {
        value: '¡Enhorabuena!',
        score: 1,
      },
    ],
  },
  {
    title: 'Te llamo cuando __________ del trabajo y vamos al teatro. ¿De acuerdo?',
    choices: [
      {
        value: 'Vaya',
        score: 0,
      },
      {
        value: 'Salga',
        score: 1,
      },
      {
        value: 'Espere',
        score: 0,
      },
    ],
  },
  {
    title: 'Oye, ¿__________ hacer una paella?',
    choices: [
      {
        value: 'Te apetecería',
        score: 1,
      },
      {
        value: 'Os gustaría',
        score: 0,
      },
      {
        value: 'Le apetecería',
        score: 0,
      },
    ],
  },
  {
    title: 'Ojalá __________ así, pero no lo es.',
    choices: [
      {
        value: 'Fuera',
        score: 1,
      },
      {
        value: 'Supiera',
        score: 0,
      },
      {
        value: 'Tuviera',
        score: 0,
      },
    ],
  },
  {
    title: '¿Se __________ pagar con tarjeta de crédito?',
    choices: [
      {
        value: 'Puede',
        score: 1,
      },
      {
        value: 'Puedo',
        score: 0,
      },
      {
        value: 'Pueden',
        score: 0,
      },
    ],
  },
  {
    title: 'Hay que ir a hacer la compra, __________ la nevera está casi vacía.',
    choices: [
      {
        value: 'Por qué',
        score: 0,
      },
      {
        value: 'Cuándo',
        score: 0,
      },
      {
        value: 'Porque',
        score: 1,
      },
    ],
  },
  {
    title: 'En verano, __________ del sol.',
    choices: [
      {
        value: 'Toma',
        score: 0,
      },
      {
        value: 'Busca',
        score: 0,
      },
      {
        value: 'Cuídate',
        score: 1,
      },
    ],
  },
];
