import Anglais from './languages/english';
import Allemand from './languages/german';
import Espagnol from './languages/spanish';
import Italien from './languages/italian';
import Fle from './languages/fle';
import Portugais from './languages/portuguese';

const SurveyData = {
  Anglais,
  Allemand,
  Espagnol,
  Italien,
  'Français FLE': Fle,
  Portugais,
};

export default SurveyData;
