import emailjs from 'emailjs-com';

const filterSubResponse = (subResponse, totalQuestion) => {
  const arraySubResponse = [];
  Object.keys(subResponse).forEach((key) => {
    arraySubResponse.push(`${key}: ${Math.round((100 / totalQuestion) * subResponse[key] * 100) / 100} %`);
  });
  return arraySubResponse;
};

const sendEmail = (data) => {
  const responses = data.questions.map((response) => `<p>${response.score ? '✅' : '❌'} => ${response.value}: ${response.response}</p>`).join('');
  const score = `${Math.round((100 / data.questions.length) * data.score)} %`;

  const templateData = {
    firstName: data.user.firstName,
    lastName: data.user.lastName,
    phone: data.user.phone,
    email: data.user.email,
    score,
    subResponse: filterSubResponse(data.subResponse, data.questions.length).join('<br>'),
    toEmail: process.env.REACT_APP_TO_EMAIL,
    responses,
  };

  return emailjs.send(
    process.env.REACT_APP_EMAIL_SERVICE_ID,
    process.env.REACT_APP_EMAIL_TEMPLATE_ID,
    templateData,
    process.env.REACT_APP_EMAIL_USER_ID,
  );
};

export default sendEmail;
