import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Survey from './Survey';
import Error from './Error';
import AppContext from '../contexts/AppContext';
import Footer from './Footer';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  color: #E66452;
  text-align: center;
  font-weight: 700;
`;

const InputText = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid black;
  border-radius: 5px;
  font-size: 1em;
  margin: 10px 0;
  padding-left: 10px;
`;

const InputCheck = styled.label`
  font-weight: 500;
  margin-bottom: 10px;
  & input {
    margin: 0;
    margin-right: 5px;
  }
`;

const Button = styled.button`
  background-color: #E66452;
  color: #FFFFFF;
  margin: 0;
  padding: 10px;
  width: 50%;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  font-size: 20px;
  &:hover {
    background-color: #E66452;
    opacity: 60%;
    cursor: pointer;
  }
  @media screen and (min-width: 800px) {
    width: 20%;
  }
`;

function SurveyWrapper({ language, survey }) {
  const context = useContext(AppContext);

  const [authorization, setAuthorization] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [phone, setPhone] = useState(null);

  const handleConfirm = () => {
    if (!email || !firstName || !lastName || !authorization || !phone) {
      setError('Merci de remplir tous les champs');
      return;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) === false) {
      setError('Merci de saisir un email valide');
      return;
    }

    if (/^((\+)33|0)[1-9](\d{2}){4}$/.test(phone) === false) {
      setError('Merci de saisir un téléphone valide');
      return;
    }

    context.user = {
      firstName,
      lastName,
      email,
      phone,
    };
    setConfirm(true);
  };

  return (
    <>
      { confirm
        ? <Survey language={language} survey={survey} />
        : (
          <Container>
            <Row>
              <Title>VOS INFORMATIONS</Title>
              <InputText type="text" placeholder="Votre Prénom" autoComplete="given-name" onChange={(event) => setFirstName(event.target.value)} />
              <InputText type="text" placeholder="Votre Nom" autoComplete="family-name" onChange={(event) => setLastName(event.target.value)} />
              <InputText type="email" placeholder="Votre Email" autoComplete="email" onChange={(event) => setEmail(event.target.value)} />
              <InputText type="phone" placeholder="Votre Téléphone" autoComplete="tel" onChange={(event) => setPhone(event.target.value)} />
              <InputCheck>
                <input type="checkbox" onChange={(event) => setAuthorization(event.target.checked)} />
                J’accepte de recevoir des emails et d’être recontacté par Prolangue
              </InputCheck>
              { error && <Error error={error} /> }
              <Button onClick={handleConfirm}>Confirmer</Button>
            </Row>
            <Footer />
          </Container>
        )}
    </>
  );
}

SurveyWrapper.propTypes = {
  language: PropTypes.string.isRequired,
  survey: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default SurveyWrapper;
