export default [
  {
    title: 'Ich __________ 24 Jahre alt. Und du ?',
    choices: [
      {
        value: 'habe',
        score: 0,
      },
      {
        value: 'bin',
        score: 1,
      },
      {
        value: 'sein',
        score: 0,
      },
    ],
  },
  {
    title: 'Guten Morgen, Simon ! Wie geht es __________?',
    choices: [
      {
        value: 'dich',
        score: 0,
      },
      {
        value: 'du',
        score: 0,
      },
      {
        value: 'dir',
        score: 1,
      },
    ],
  },
  {
    title: 'Am Freitag fahre ich __________  meinen Eltern.',
    choices: [
      {
        value: 'mit',
        score: 0,
      },
      {
        value: 'nach',
        score: 0,
      },
      {
        value: 'zu',
        score: 1,
      },
    ],
  },
  {
    title: 'Das Mädchen freute sich als __________ Mutter nach Hause kam.',
    choices: [
      {
        value: 'seine',
        score: 0,
      },
      {
        value: 'ihre',
        score: 1,
      },
      {
        value: 'sie',
        score: 0,
      },
    ],
  },
  {
    title: 'Wie fragen Sie nach dem Preis ?',
    choices: [
      {
        value: 'Wie lang ist das ?',
        score: 0,
      },
      {
        value: 'Wie viel kostet das ?',
        score: 1,
      },
      {
        value: 'Darf ich kosten ?',
        score: 0,
      },
    ],
  },
  {
    title: 'Claudia verdient viel Geld, Max verdient mehr, aber Petra verdient __________ .',
    choices: [
      {
        value: 'gut',
        score: 0,
      },
      {
        value: 'besser',
        score: 0,
      },
      {
        value: 'am meisten',
        score: 1,
      },
    ],
  },
  {
    title: 'Entschuldigung! Sie __________ hier nicht hineingehen! Hier ist nur der Ausgang! Der Eingang ist dort hinten. ',
    choices: [
      {
        value: 'müssen',
        score: 0,
      },
      {
        value: 'sollen',
        score: 0,
      },
      {
        value: 'dürfen',
        score: 1,
      },
    ],
  },
  {
    title: 'Wie lange ist das Auto schon kaputt ?',
    choices: [
      {
        value: 'Erst einen Tag.',
        score: 1,
      },
      {
        value: 'Jeden Tag.',
        score: 0,
      },
      {
        value: 'Bald.',
        score: 0,
      },
    ],
  },
  {
    title: 'Wie findest du den __________ Pullover ?',
    choices: [
      {
        value: 'grün',
        score: 0,
      },
      {
        value: 'grünen',
        score: 1,
      },
      {
        value: 'grünes',
        score: 0,
      },
    ],
  },
  {
    title: 'Paul sucht __________ Internet ein Fahrrad.',
    choices: [
      {
        value: 'vom',
        score: 0,
      },
      {
        value: 'im',
        score: 1,
      },
      {
        value: 'am ',
        score: 0,
      },
    ],
  },
  {
    title: 'Ich kaufe __________ Hose.',
    choices: [
      {
        value: 'eine',
        score: 1,
      },
      {
        value: 'ein',
        score: 0,
      },
      {
        value: 'einen',
        score: 0,
      },
    ],
  },
  {
    title: 'Ich bestelle im Café __________.',
    choices: [
      {
        value: 'der Tee und der Kuchen.',
        score: 0,
      },
      {
        value: 'einen Tee und ein Stück Kuchen.',
        score: 1,
      },
      {
        value: 'mit Tee und Kuchen.',
        score: 0,
      },
    ],
  },
  {
    title: 'Sie hat einen Ehemann. Sie ist __________ .',
    choices: [
      {
        value: 'geschieden',
        score: 0,
      },
      {
        value: 'ledig',
        score: 0,
      },
      {
        value: 'verheiratet',
        score: 1,
      },
    ],
  },
  {
    title: '__________ morgen die Sonne scheint, können wir ins Schwimmbad gehen.',
    choices: [
      {
        value: 'Weil',
        score: 0,
      },
      {
        value: 'Wenn',
        score: 1,
      },
      {
        value: 'Wann',
        score: 0,
      },
    ],
  },
  {
    title: 'Der Käse schmeckt Ihnen sehr gut. Was sagen Sie ?',
    choices: [
      {
        value: 'Furchtbar !',
        score: 0,
      },
      {
        value: 'Lecker !',
        score: 1,
      },
      {
        value: 'Wunderschön !',
        score: 0,
      },
    ],
  },
  {
    title: 'Um wieviel Uhr bist du __________?',
    choices: [
      {
        value: 'aufstehen',
        score: 0,
      },
      {
        value: 'aufgestanden',
        score: 1,
      },
      {
        value: 'steh auf',
        score: 0,
      },
    ],
  },
  {
    title: 'Valentinstag ist am __________ Februar (14.Februar).',
    choices: [
      {
        value: 'vierzehn',
        score: 0,
      },
      {
        value: 'vierzehnten',
        score: 1,
      },
      {
        value: 'vierzigsten',
        score: 0,
      },
    ],
  },
  {
    title: 'Wo __________ heute Abend die Feier stattfinden ?',
    choices: [
      {
        value: 'werden',
        score: 0,
      },
      {
        value: 'wurde',
        score: 0,
      },
      {
        value: 'wird',
        score: 1,
      },
    ],
  },
  {
    title: 'Hast du __________ Brief geschrieben ?',
    choices: [
      {
        value: 'dieser',
        score: 0,
      },
      {
        value: 'diesen',
        score: 1,
      },
      {
        value: 'diese',
        score: 0,
      },
    ],
  },
  {
    title: 'Was ist ein Synonym des Wortes « gehen » ?',
    choices: [
      {
        value: 'machen',
        score: 0,
      },
      {
        value: 'laufen',
        score: 1,
      },
      {
        value: 'lesen',
        score: 0,
      },
    ],
  },
  {
    title: 'Es war ein schöner Abend, vielen Dank für die __________.',
    choices: [
      {
        value: 'Brief',
        score: 0,
      },
      {
        value: 'Einladung',
        score: 1,
      },
      {
        value: 'Abenteuer',
        score: 0,
      },
    ],
  },
  {
    title: 'Das Buch liegt __________ dem Tisch.',
    choices: [
      {
        value: 'zu',
        score: 0,
      },
      {
        value: 'in',
        score: 0,
      },
      {
        value: 'auf',
        score: 1,
      },
    ],
  },
  {
    title: 'Wie sagt man 2021 ?',
    choices: [
      {
        value: 'zweitausendeinundzwanzig',
        score: 1,
      },
      {
        value: 'zwanzigtausendzwei',
        score: 0,
      },
      {
        value: 'zweihunderteinundzwanzig',
        score: 0,
      },
    ],
  },
  {
    title: 'Ich esse gern __________.',
    choices: [
      {
        value: 'Hamburg',
        score: 0,
      },
      {
        value: 'Kuchen',
        score: 1,
      },
      {
        value: 'München',
        score: 0,
      },
    ],
  },
  {
    title: 'Manche Wörter passen zueinander, wie « heißer » und « Sommer », was passt zu « Kaffee » ?',
    choices: [
      {
        value: 'stiller',
        score: 0,
      },
      {
        value: 'starker',
        score: 1,
      },
      {
        value: 'schöner',
        score: 0,
      },
    ],
  },
  {
    title: 'Man sagt « ein kleines Mädchen », was passt zu « ein großer __________ ».',
    choices: [
      {
        value: 'Haus',
        score: 0,
      },
      {
        value: 'Hund',
        score: 1,
      },
      {
        value: 'Schule',
        score: 0,
      },
    ],
  },
];
