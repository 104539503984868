import React from 'react';
import styled from 'styled-components';
import logo from '../assets/logo.svg';

const Container = styled.div`
  width: 100%;
  height: 15%;
  background-color: #232358;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Img = styled.img`
  height: 60%;
`;

function Footer() {
  return (
    <Container>
      <Img src={logo} alt="logo prolangue" />
    </Container>
  );
}

export default Footer;
