export default [
  {
    title: 'Jennifer is __________  English teacher.',
    choices: [
      {
        value: 'a',
        score: 0,
      },
      {
        value: 'an',
        score: 1,
      },
      {
        value: '/',
        score: 0,
      },
    ],
  },
  {
    title: 'I like to eat __________ in the morning.',
    choices: [
      {
        value: 'breakfast',
        score: 1,
      },
      {
        value: 'lunch',
        score: 0,
      },
      {
        value: 'meal',
        score: 0,
      },
    ],
  },
  {
    title: 'What is the opposite of "small"',
    choices: [
      {
        value: 'tiny',
        score: 0,
      },
      {
        value: 'short',
        score: 0,
      },
      {
        value: 'big',
        score: 1,
      },
    ],
  },
  {
    title: 'I\'m sorry, I __________ understand.',
    choices: [
      {
        value: 'am not',
        score: 0,
      },
      {
        value: 'don\'t',
        score: 1,
      },
      {
        value: 'not',
        score: 0,
      },
    ],
  },
  {
    title: 'Please find __________ my resume. (in an e-mail)',
    choices: [
      {
        value: 'attached',
        score: 1,
      },
      {
        value: 'joint',
        score: 0,
      },
      {
        value: 'connected',
        score: 0,
      },
    ],
  },
  {
    title: 'Yesterday, I __________ a new pair of shoes.',
    choices: [
      {
        value: 'buyed',
        score: 0,
      },
      {
        value: 'bought',
        score: 1,
      },
      {
        value: 'buying',
        score: 0,
      },
    ],
  },
  {
    title: 'He speaks __________ English __________ his brother.',
    choices: [
      {
        value: 'as good... like',
        score: 0,
      },
      {
        value: 'better... that',
        score: 0,
      },
      {
        value: 'better... than',
        score: 1,
      },
    ],
  },
  {
    title: 'The train leaves at 9:30 am. Don\'t be __________!',
    choices: [
      {
        value: 'late',
        score: 1,
      },
      {
        value: 'in late',
        score: 0,
      },
      {
        value: 'in lateness',
        score: 0,
      },
    ],
  },
  {
    title: 'Could you give me some __________ on this proposal?',
    choices: [
      {
        value: 'advices',
        score: 0,
      },
      {
        value: 'feedback',
        score: 1,
      },
      {
        value: 'remark',
        score: 0,
      },
    ],
  },
  {
    title: 'I\'m afraid the project will be delayed __________ some shortages.',
    choices: [
      {
        value: 'due to',
        score: 1,
      },
      {
        value: 'because',
        score: 0,
      },
      {
        value: 'as of',
        score: 0,
      },
    ],
  },
  {
    title: 'I wish I __________ more time to travel.',
    choices: [
      {
        value: 'have',
        score: 0,
      },
      {
        value: 'will have',
        score: 0,
      },
      {
        value: 'had',
        score: 1,
      },
    ],
  },
  {
    title: 'When driving, you __________ wear your safety belt.',
    choices: [
      {
        value: 'must',
        score: 1,
      },
      {
        value: 'may',
        score: 0,
      },
      {
        value: 'could',
        score: 0,
      },
    ],
  },
  {
    title: 'How long __________ in this company?',
    choices: [
      {
        value: 'do you work',
        score: 0,
      },
      {
        value: 'have you been working',
        score: 1,
      },
      {
        value: 'are you working',
        score: 0,
      },
    ],
  },
  {
    title: 'Which sentence would you use to schedule a meeting?',
    choices: [
      {
        value: 'Can we postpone the meeting?',
        score: 0,
      },
      {
        value: 'Let\'s talk about the agenda!',
        score: 0,
      },
      {
        value: 'Can we set up a meeting?',
        score: 1,
      },
    ],
  },
  {
    title: 'What\'s the opposite of "loss" in Business English?',
    choices: [
      {
        value: 'gain',
        score: 1,
      },
      {
        value: 'advantage',
        score: 0,
      },
      {
        value: 'benefit',
        score: 0,
      },
    ],
  },
  {
    title: 'By the time we arrived, they __________ dinner.',
    choices: [
      {
        value: 'have already had',
        score: 0,
      },
      {
        value: 'had already',
        score: 0,
      },
      {
        value: 'had already had',
        score: 1,
      },
    ],
  },
  {
    title: 'She takes__________ her father: same eyes, same smile and same laugh!',
    choices: [
      {
        value: 'after',
        score: 1,
      },
      {
        value: 'up to',
        score: 0,
      },
      {
        value: 'like',
        score: 0,
      },
    ],
  },
  {
    title: 'The negotiation process went __________: both parties reached an agreement.',
    choices: [
      {
        value: 'correctly',
        score: 0,
      },
      {
        value: 'smoothly',
        score: 1,
      },
      {
        value: 'accordingly',
        score: 0,
      },
    ],
  },
  {
    title: 'Could you please __________ a proposal for the new project?',
    choices: [
      {
        value: 'put together',
        score: 1,
      },
      {
        value: 'put in',
        score: 0,
      },
      {
        value: 'put up with',
        score: 0,
      },
    ],
  },
  {
    title: 'I\'ll call you as soon as I __________ home.',
    choices: [
      {
        value: 'will get',
        score: 0,
      },
      {
        value: 'get',
        score: 1,
      },
      {
        value: 'will come',
        score: 0,
      },
    ],
  },
  {
    title: 'Be __________ of pickpockets in this area of the city.',
    choices: [
      {
        value: 'wary',
        score: 1,
      },
      {
        value: 'prude',
        score: 0,
      },
      {
        value: 'spurious',
        score: 0,
      },
    ],
  },
  {
    title: 'I had never seen it before, and __________ I knew exactly what to do.',
    choices: [
      {
        value: 'still',
        score: 0,
      },
      {
        value: 'yet',
        score: 1,
      },
      {
        value: 'even',
        score: 0,
      },
    ],
  },
  {
    title: 'We had to listen to all the __________ details of his operation.',
    choices: [
      {
        value: 'impervious',
        score: 0,
      },
      {
        value: 'opportune',
        score: 0,
      },
      {
        value: 'tedious',
        score: 1,
      },
    ],
  },
  {
    title: '__________ you were coming, I would have baked a cake!',
    choices: [
      {
        value: 'Having known',
        score: 0,
      },
      {
        value: 'Had I known',
        score: 1,
      },
      {
        value: 'Not knowing',
        score: 0,
      },
    ],
  },
  {
    title: 'Which idiom means "not having the experience/knowledge to deal with a particular situation/activity"?',
    choices: [
      {
        value: 'to be in deep water',
        score: 0,
      },
      {
        value: 'to be out of your depth',
        score: 1,
      },
      {
        value: 'to be like a fish out of water',
        score: 0,
      },
    ],
  },
];
