import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SurveyWrapper from './SurveyWrapper';
import logo from '../assets/logo.svg';
import gif from '../assets/languages.gif';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #232358;
  display: flex;
  justify-content: center;
`;

const Row = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Header = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
  }
  text-align: center;
`;

const TitleContainer = styled.div`
  @media screen and (min-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const Logo = styled.img`
  width: 300px;
  @media screen and (min-width: 800px) {
    width: 400px;
  }
`;

const Title = styled.h1`
  color: #E66452;
  text-align: center;
  font-weight: 700;
  font-size: 1.6em;
  @media screen and (min-width: 800px) {
    font-size: 2em;
    margin-top: 50px;
  }
`;

const Gif = styled.img`
  width: 200px;
  margin: -20px;
  @media screen and (min-width: 800px) {
    width: 400px;
  }
`;

const Content = styled.p`
  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
  font-size: 1em;
  @media screen and (min-width: 800px) {
    font-size: 1.4em;
    margin-top: 50px;
  }
`;

const Select = styled.select`
  background-color: #FFFFFF;
  border: 1px solid black;
  margin: 20px 0 10px;
  padding: 5px;
  width: 70%;
  font-size: 1.2em;
  border-radius: 5px;
  outline: none;
  @media screen and (min-width: 800px) {
    font-size: 1.5em;
    padding: 10px;
    width: 40%;
  }
`;

const Button = styled.button`
  background-color: #E66452;
  color: #FFFFFF;
  margin: 0;
  padding: 10px;
  width: 50%;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  font-size: 20px;
  &:hover {
    background-color: #E66452;
    opacity: 60%;
    cursor: pointer;
  }
  @media screen and (min-width: 800px) {
    width: 20%;
  }
`;

function Home({ survey }) {
  const [language, setLanguage] = useState(Object.keys(survey)[0]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const handleLanguage = (event) => {
    setLanguage(event.target.value);
  };

  const handleConfirmation = () => {
    if (Object.keys(survey).includes(language)) {
      setIsConfirmed(true);
    }
  };

  return (
    <>
      { isConfirmed
        ? <SurveyWrapper survey={survey} language={language} />
        : (
          <Container>
            <Row>
              <Header>
                <TitleContainer>
                  <Logo src={logo} alt="logo prolangue" />
                  <Title>TESTER VOTRE NIVEAU EN LANGUE</Title>
                </TitleContainer>
                <Gif src={gif} alt="animation langues" />
              </Header>
              <Content>
                Voici un test rapide et simple pour avoir une idée de votre niveau académique !
                <br />
                Faites-le à l&apos;instinct et répondez à toutes
                les questions même si vous n&apos;êtes pas sûr de vous.
                Vous en savez certainement plus que vous ne le croyez !
              </Content>
              <Select onChange={handleLanguage}>
                { Object.keys(survey).map(
                  (lang) => <option key={lang} value={lang}>{lang}</option>,
                )}
              </Select>
              <Button onClick={handleConfirmation}>c&apos;est parti</Button>
            </Row>
          </Container>
        )}
    </>
  );
}

Home.propTypes = {
  survey: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default Home;
