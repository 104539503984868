import React, { useContext, useEffect } from 'react';
import styled from 'styled-components';
import AppContext from '../contexts/AppContext';
import sendEmail from '../utils/EmailSender';
import crazy from '../assets/crazy.png';
import logo from '../assets/logo.svg';

const Container = styled.div`
  height: 100%;
  width: 100%;
  background-color: #232358;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 300px;
  @media screen and (min-width: 800px) {
    width: 400px;
  }
`;

const Img = styled.img`
  display: flex;
  width: 200px;
  margin: 20px 0;
  border-radius: 30px;
  @media screen and (min-width: 800px) {
    width: 300px;
  }
`;

const Content = styled.p`
  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
  margin-top: 0;
  font-size: 1em;
  @media screen and (min-width: 800px) {
    font-size: 1.4em;
  }
`;

const Button = styled.button`
  background-color: #E66452;
  color: #FFFFFF;
  margin: 0;
  padding: 10px;
  width: 60%;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  font-size: 20px;
  &:hover {
    background-color: #E66452;
    opacity: 60%;
    cursor: pointer;
  }
  @media screen and (min-width: 800px) {
    width: 20%;
  }
`;

const Footer = styled.a`
  color: white;
  align-items: center;
`;

function End() {
  const context = useContext(AppContext);

  useEffect(() => {
    sendEmail(context);
  });

  const goToWebsite = () => {
    window.location.href = 'https://www.prolangue-formation.com';
  };

  return (
    <Container>
      <Row>
        <Logo src={logo} alt="logo prolangue" />
        <Img src={crazy} alt="image femme bravo" />
        <Content>
          Merci d&apos;avoir été au bout de ce test de niveau en langue.
          <br />
          Vous recevrez sous peu un mail indiquant votre niveau académique selon le CECRL
          (Cadre européen commun de référence pour les langues).
          Connaître son niveau à l&apos;écrit c&apos;est intéressant
          mais que diriez-vous de connaître votre niveau à l&apos;oral ?
          Nous vous recevons sur rendez-vous et sans engagement de votre part pour un
          test de positionnement oral !
          Une langue, c&apos;est quand même mieux quand on la parle !😉
        </Content>
        <Button onClick={goToWebsite}>Visitez notre site</Button>
      </Row>
      <Footer
        href="https://www.prolangue-formation.com/politique-confidentialite"
        target="_blank"
      >
        Politique de confidentialité
      </Footer>
    </Container>
  );
}

export default End;
