import React from 'react';
import Home from './components/Home';
import AppContext from './contexts/AppContext';
import { notSure, sure } from './utils/SubResponse';
import SurveyData from './utils/SurveyData';

function App() {
  const defaultState = {
    questions: [],
    score: 0,
    subResponse: {
      [sure]: 0,
      [notSure]: 0,
    },
    user: {},
  };

  return (
    <AppContext.Provider value={defaultState}>
      <Home survey={SurveyData} />
    </AppContext.Provider>
  );
}

export default App;
