export default [
  {
    title: 'Bonjour. Quel est votre nom de famille, s\'il vous plaît ?',
    choices: [
      { value: 'Je m\'appelle Aurélie.', score: 0 },
      { value: 'Mon nom est Laforge.', score: 1 },
      { value: 'Enchantée !', score: 0 },
    ],
  },
  {
    title: 'Tu __________ français ?',
    choices: [
      { value: 'parles', score: 1 },
      { value: 'parlent', score: 0 },
      { value: 'parle', score: 0 },
    ],
  },
  {
    title: 'Pour manger, utilise ta fourchette et ton __________.',
    choices: [
      { value: 'cuillère', score: 0 },
      { value: 'verre', score: 0 },
      { value: 'couteau', score: 1 },
    ],
  },
  {
    title: '__________ tu bois le matin ? Du café ou du thé ?',
    choices: [
      { value: 'Que', score: 0 },
      { value: 'Quoi', score: 0 },
      { value: 'Qu\'est-ce que', score: 1 },
    ],
  },
  {
    title: 'Le fils de ma soeur est mon __________.',
    choices: [
      { value: 'neveu', score: 1 },
      { value: 'oncle', score: 0 },
      { value: 'frère', score: 0 },
    ],
  },
  {
    title: 'Hier, il __________ une pizza avec ses amis.',
    choices: [
      { value: 'mangea', score: 0 },
      { value: 'a mangé', score: 1 },
      { value: 'est mangé', score: 0 },
    ],
  },
  {
    title: 'L\'année prochaine, je __________ le marathon de Paris.',
    choices: [
      { value: 'ferai', score: 1 },
      { value: 'feras', score: 0 },
      { value: 'ferais', score: 0 },
    ],
  },
  {
    title: 'Complétez cette expression : "Il fait un temps de __________" (il fait mauvais temps).',
    choices: [
      { value: 'canard', score: 0 },
      { value: 'vache', score: 0 },
      { value: 'chien', score: 1 },
    ],
  },
  {
    title: 'Pense à prendre tes__________ pour le sport !',
    choices: [
      { value: 'baskets', score: 1 },
      { value: 'pantoufles', score: 0 },
      { value: 'escarpins', score: 0 },
    ],
  },
  {
    title: 'Il faut absolument que tu __________ à la réunion demain.',
    choices: [
      { value: 'viendras', score: 0 },
      { value: 'viennes', score: 1 },
      { value: 'viens', score: 0 },
    ],
  },
  {
    title: 'Chaque chose en son temps :__________ tu apprends le vocabulaire, __________ tu corriges la grammaire.',
    choices: [
      { value: 'd\'abord....puis', score: 1 },
      { value: 'premier...deuxième...', score: 0 },
      { value: 'd\'une part..... d\'autre part...', score: 0 },
    ],
  },
  {
    title: 'Complétez cette expression : "avoir les __________ plus gros que le __________" (être trop ambitieux ou gourmand)',
    choices: [
      { value: 'doigts...pied', score: 0 },
      { value: 'yeux...ventre', score: 1 },
      { value: 'pieds...corps', score: 0 },
    ],
  },
  {
    title: 'Le client __________ j\'ai rencontré hier m\'a semblé très intéressé.',
    choices: [
      { value: 'que', score: 1 },
      { value: 'lequel', score: 0 },
      { value: 'qui', score: 0 },
    ],
  },
  {
    title: 'Que signifie l\'expression "avoir la tête dans le guidon" ?',
    choices: [
      { value: 'être très motivé', score: 0 },
      { value: 'avoir beaucoup de travail', score: 1 },
      { value: 'avoir des ennuis', score: 0 },
    ],
  },
  {
    title: 'Nous avons passé d\'excellentes vacances __________ la pluie.',
    choices: [
      { value: 'malgré', score: 1 },
      { value: 'à défaut de', score: 0 },
      { value: 'sauf', score: 0 },
    ],
  },
  {
    title: 'Nous __________ à Paris la semaine dernière.',
    choices: [
      { value: 'avons été', score: 0 },
      { value: 'avons étés', score: 0 },
      { value: 'sommes allés', score: 1 },
    ],
  },
  {
    title: 'Il faut que tu confirmes à Sarah si tu viens ou pas. __________ dès que possible !',
    choices: [
      { value: 'Dis-lui-le', score: 0 },
      { value: 'Dis-le-lui', score: 1 },
      { value: 'Dis-la-lui', score: 0 },
    ],
  },
  {
    title: 'Reformulez dans un français soutenu : "Il s\'est fait virer comme un malpropre !"',
    choices: [
      { value: 'Il a été licencié de façon abrupte et irrespectueuse.', score: 1 },
      { value: 'Il a été licencié à cause d\'un manque d\'hygiène.', score: 0 },
      { value: 'Il a été critiqué par des gens sales.', score: 0 },
    ],
  },
  {
    title: 'Le jour __________ il a été élu président, il a pris la grosse tête !',
    choices: [
      { value: 'qu\'', score: 0 },
      { value: 'quand', score: 0 },
      { value: 'où', score: 1 },
    ],
  },
  {
    title: 'Si je __________ à la lotterie, je __________ faire le tour du monde.',
    choices: [
      { value: 'gagnerais...partirais', score: 0 },
      { value: 'gagnais...partirais', score: 1 },
      { value: 'gagnais...partirai', score: 0 },
    ],
  },
  {
    title: 'Tout le monde est accepté dans ce groupe __________ de son niveau.',
    choices: [
      { value: 'indépendemment', score: 0 },
      { value: 'indépendamment', score: 1 },
      { value: 'indépendament', score: 0 },
    ],
  },
  {
    title: 'J\'aimerais parler correctement français, __________ couramment !',
    choices: [
      { value: 'voire même', score: 0 },
      { value: 'même si', score: 0 },
      { value: 'voire', score: 1 },
    ],
  },
  {
    title: 'Après le cours de natation, elle s\'est __________ les cheveux.',
    choices: [
      { value: 'lavée', score: 0 },
      { value: 'lavé', score: 1 },
      { value: 'lavés', score: 0 },
    ],
  },
  {
    title: 'Tu as raison. Je me suis trompée. __________ pour moi !',
    choices: [
      { value: 'Ô temps', score: 0 },
      { value: 'Au temps', score: 1 },
      { value: 'Autant', score: 0 },
    ],
  },
  {
    title: 'Laquelle de ces expressions signifie "passer d\'un sujet à un autre sans lien ni transition" ?',
    choices: [
      { value: 'passer de charybde en scylla', score: 0 },
      { value: 'passer de A à Z', score: 0 },
      { value: 'passer du coq à l\'âne', score: 1 },
    ],
  },
];
