export default [
  {
    title: 'Marco __________ un ragazzo spagnolo.',
    choices: [
      {
        value: 'ha',
        score: 0,
      },
      {
        value: 'è',
        score: 1,
      },
      {
        value: 'sono',
        score: 0,
      },
    ],
  },
  {
    title: 'Ti presento Nicola, è __________ fratello',
    choices: [
      {
        value: 'suo',
        score: 0,
      },
      {
        value: 'mio',
        score: 1,
      },
      {
        value: 'vostro',
        score: 0,
      },
    ],
  },
  {
    title: 'Marina __________ a Milano domani in treno.',
    choices: [
      {
        value: 'partiva',
        score: 0,
      },
      {
        value: 'andranno',
        score: 0,
      },
      {
        value: 'parte',
        score: 1,
      },
    ],
  },
  {
    title: 'Di dove sei ?',
    choices: [
      {
        value: 'Sono di Frenze',
        score: 1,
      },
      {
        value: 'Sono da Firenze',
        score: 0,
      },
      {
        value: 'Vado a Firenze',
        score: 0,
      },
    ],
  },
  {
    title: '__________ torni a casa tua ?',
    choices: [
      {
        value: 'Quando',
        score: 1,
      },
      {
        value: 'Quanto',
        score: 0,
      },
      {
        value: 'Se',
        score: 0,
      },
    ],
  },
  {
    title: 'Maria __________ portato una torta per il mio compleanno.',
    choices: [
      {
        value: 'è',
        score: 0,
      },
      {
        value: 'ha',
        score: 1,
      },
      {
        value: 'ho',
        score: 0,
      },
    ],
  },
  {
    title: 'A mezzogiorno arriva mia cugina per __________',
    choices: [
      {
        value: 'pranzo',
        score: 1,
      },
      {
        value: 'cena',
        score: 0,
      },
      {
        value: 'colazione',
        score: 0,
      },
    ],
  },
  {
    title: 'A Tommaso piace molto la musica italiana, a noi __________ la musica amercana.',
    choices: [
      {
        value: 'piaceva',
        score: 0,
      },
      {
        value: 'ci piace',
        score: 1,
      },
      {
        value: 'piaciamo',
        score: 0,
      },
    ],
  },
  {
    title: 'L\'estate scorsa (io)__________ in vacanze a Milano.',
    choices: [
      {
        value: 'sono arrivato',
        score: 0,
      },
      {
        value: 'sono andata',
        score: 1,
      },
      {
        value: 'parto',
        score: 0,
      },
    ],
  },
  {
    title: 'Stamattina __________ al mercato ho incontrato Alice.',
    choices: [
      {
        value: 'andando',
        score: 1,
      },
      {
        value: 'andasse',
        score: 0,
      },
      {
        value: 'andavo',
        score: 0,
      },
    ],
  },
  {
    title: 'Non mi fido mai di __________ non mi guarda negli occhi.',
    choices: [
      {
        value: 'quanti',
        score: 0,
      },
      {
        value: 'chi',
        score: 1,
      },
      {
        value: 'come',
        score: 0,
      },
    ],
  },
  {
    title: 'Temo che a Maria non __________ il regalo che gli ho dato ieri.',
    choices: [
      {
        value: 'fosse piaciuto',
        score: 0,
      },
      {
        value: 'avrà piaciuto',
        score: 0,
      },
      {
        value: 'sia piaciuto',
        score: 1,
      },
    ],
  },
  {
    title: 'Federico ! Sono le otto ! __________',
    choices: [
      {
        value: 'Ti svegli!',
        score: 0,
      },
      {
        value: 'Svegliati!',
        score: 1,
      },
      {
        value: 'Sveglia!',
        score: 0,
      },
    ],
  },
  {
    title: 'Pensi che verranno __________ I tuoi amici alla festa di sabato prossimo?',
    choices: [
      {
        value: 'tutto',
        score: 0,
      },
      {
        value: 'tutti',
        score: 1,
      },
      {
        value: 'tutte',
        score: 0,
      },
    ],
  },
  {
    title: 'Andando in vacanze __________. mare ,ci siamo fermati in __________ paese molto carino.',
    choices: [
      {
        value: 'il / uno',
        score: 0,
      },
      {
        value: 'il / lo',
        score: 0,
      },
      {
        value: 'al / un',
        score: 1,
      },
    ],
  },
  {
    title: 'Quel film è molto violento ! __________ anche gli adulti , non solo i bambini !',
    choices: [
      {
        value: 'da scoprire',
        score: 0,
      },
      {
        value: 'da spaventare',
        score: 1,
      },
      {
        value: 'da diventare',
        score: 0,
      },
    ],
  },
  {
    title: 'Avete pagato il conto del ristorante alle ragazze? Ma certo che __________ pagato!',
    choices: [
      {
        value: 'ce l\'abbiamo',
        score: 0,
      },
      {
        value: 'le abbiamo',
        score: 0,
      },
      {
        value: 'gliel\'abbiamo',
        score: 1,
      },
    ],
  },
  {
    title: 'Ti faccio vedere come funziona in modo che tu __________ farlo da solo.',
    choices: [
      {
        value: 'possa',
        score: 1,
      },
      {
        value: 'possi',
        score: 0,
      },
      {
        value: 'potevi',
        score: 0,
      },
    ],
  },
  {
    title: 'Il treno parte alle nove ! Sbrigati , sei in __________ ',
    choices: [
      {
        value: 'ricordo',
        score: 0,
      },
      {
        value: 'rammento',
        score: 0,
      },
      {
        value: 'ritardo',
        score: 1,
      },
    ],
  },
  {
    title: 'Marco vuole __________ stasera.',
    choices: [
      {
        value: 'esce',
        score: 0,
      },
      {
        value: 'uscire',
        score: 1,
      },
      {
        value: 'escere',
        score: 0,
      },
    ],
  },
  {
    title: 'Sabato scorso __________ volentieri al teatro , ma siamo arrivati troppo tardi',
    choices: [
      {
        value: 'saremmo andati',
        score: 1,
      },
      {
        value: 'vado',
        score: 0,
      },
      {
        value: 'siamo stati',
        score: 0,
      },
    ],
  },
  {
    title: '__________ lei lo avesse tradito,continuavano a considerarsi una coppia felice.',
    choices: [
      {
        value: 'Nonostante',
        score: 1,
      },
      {
        value: 'Siccome',
        score: 0,
      },
      {
        value: 'Eppure',
        score: 0,
      },
    ],
  },
  {
    title: 'Non sei mai stato tanto __________ da piangere?',
    choices: [
      {
        value: 'disperato',
        score: 1,
      },
      {
        value: 'dimesso',
        score: 0,
      },
      {
        value: 'spiacevole',
        score: 0,
      },
    ],
  },
  {
    title: 'Pensi che Andrea ce la __________ a venire a prendermi a casa mia ?',
    choices: [
      {
        value: 'abbia',
        score: 0,
      },
      {
        value: 'va',
        score: 0,
      },
      {
        value: 'faccia',
        score: 1,
      },
    ],
  },
  {
    title: 'Nel caso in cui __________ invitati , andremmo alla festa.',
    choices: [
      {
        value: 'saremo',
        score: 0,
      },
      {
        value: 'siamo',
        score: 0,
      },
      {
        value: 'fossimo',
        score: 1,
      },
    ],
  },
  {
    title: 'Mandateci i vostri messagi e __________ li leggeremo.',
    choices: [
      {
        value: 'or ora',
        score: 0,
      },
      {
        value: 'man mano',
        score: 0,
      },
      {
        value: 'via via',
        score: 1,
      },
    ],
  },
];
