import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import End from './End';
import Footer from './Footer';
import AppContext from '../contexts/AppContext';
import Error from './Error';
import { subResponseValue } from '../utils/SubResponse';

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Row = styled.div`
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (min-width: 800px) {
    width: 60%;
  }
`;

const Title = styled.h1`
  color: #E66452;
  text-align: center;
  font-weight: 700;
`;

const Button = styled.button`
  background-color: #E66452;
  color: #FFFFFF;
  margin: 0;
  padding: 10px;
  width: 50%;
  border-radius: 5px;
  border: none;
  margin-top: 10px;
  font-size: 20px;
  &:hover {
    background-color: #E66452;
    opacity: 60%;
    cursor: pointer;
  }
  @media screen and (min-width: 800px) {
    width: 40%;
  }
`;

const ChoiceContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Choice = styled.label`
  font-size: 1.5em;
  margin: 5px 0;
  & span {
    margin-left: 10px;
  }
`;

const SubResponseContainer = styled.div`
  margin-top: 10px;
  background-color: #232358;
  color: #ffffff;
  font-weight: 400;
  padding: 10px;
  border-radius: 5px;
`;

const SubResponses = styled.div`
  display: flex;
  justify-content: center;
`;

const SubResponseSpan = styled.div`
  font-size: 1.2em;
  text-align: center;
`;

const SubChoice = styled.label`
  font-size: 1.2em;
  margin: 5px 10px;
  & span {
    margin-left: 5px;
  }
`;

function Survey({ language, survey }) {
  const context = useContext(AppContext);

  const questions = survey[language];
  const surveyCount = questions.length;
  const [questionNumber, setQuestionNumber] = useState(0);
  const [question, setQuestion] = useState(questions[questionNumber]);
  const [end, setEnd] = useState(false);
  const [response, setResponse] = useState(null);
  const [showSubResponse, setShowSubResponse] = useState(false);
  const [subResponse, setSubResponse] = useState(null);
  const [error, setError] = useState(null);

  const handleResponse = (event) => {
    setResponse(event.target.value);
  };

  const handleChange = () => {
    setShowSubResponse(true);
  };

  const handleSubResponse = (event) => {
    setSubResponse(event.target.value);
  };

  const handleNext = () => {
    if (response === null || subResponse === null) {
      setError('vous devez répondre à la question');
      return;
    }
    question.choices.forEach((choice) => {
      if (choice.value === response) {
        context.score += choice.score;
        context.questions = [
          ...context.questions,
          {
            value: question.title,
            response,
            score: choice.score,
          },
        ];
      }
    });
    context.subResponse[subResponse] += 1;

    const nextQuestion = questionNumber + 1;
    if (surveyCount === nextQuestion) {
      setEnd(true);
    } else {
      setQuestionNumber(nextQuestion);
      setQuestion(questions[nextQuestion]);
    }
    setResponse(null);
    setSubResponse(null);
    setShowSubResponse(false);
    setError(null);
  };

  return (
    <>
      { end
        ? <End />
        : (
          <Container>
            <Row>
              <Title dangerouslySetInnerHTML={{ __html: question.title }} />
              <ChoiceContainer onChange={handleChange}>
                {question.choices.map((choice, key) => (
                  <Choice key={choice.value}>
                    <input
                      type="radio"
                      name={`response_${questionNumber}_${key}`}
                      value={choice.value}
                      onClick={handleResponse}
                      checked={choice.value === response ?? false}
                    />
                    <span>{choice.value}</span>
                  </Choice>
                ))}
              </ChoiceContainer>
              { showSubResponse && (
                <SubResponseContainer>
                  <SubResponseSpan>Vous êtes :</SubResponseSpan>
                  <SubResponses>
                    { subResponseValue.map((res) => (
                      <SubChoice key={res}>
                        <input type="radio" name="sub-response" value={res} onClick={handleSubResponse} />
                        <span>{res}</span>
                      </SubChoice>
                    )) }
                  </SubResponses>
                </SubResponseContainer>

              )}
              { error && <Error error={error} /> }
              <Button disabled={!response || !subResponse} onClick={handleNext}>Next</Button>
            </Row>
            <Footer />
          </Container>
        )}
    </>
  );
}

Survey.propTypes = {
  language: PropTypes.string.isRequired,
  survey: PropTypes.objectOf(PropTypes.array).isRequired,
};

export default Survey;
